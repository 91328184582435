'use strict'
const doesHaveAll = (props, obj) => props.every(propName => !!obj[propName])
const createCssResolver = (mandatoryProps, resolver) => styleData => doesHaveAll(mandatoryProps, styleData) ? resolver(styleData) : ''

const fontFamilyResolver = createCssResolver(['fontFamily'], ({fontFamily}) => `font-family:${fontFamily};`)
const fontWeightResolver = createCssResolver(['fontWeight'], ({fontWeight}) => `font-weight:${fontWeight};`)
const fontStyleResolver = createCssResolver(['fontStyle'], ({fontStyle}) => `font-style:${fontStyle};`)
const textDecorationResolver = createCssResolver(['textDecoration'], ({textDecoration}) => `text-decoration:${textDecoration};`)
const textAlignResolver = createCssResolver(['textAlign'], ({textAlign}) => `text-align:${textAlign};`)
const letterSpacingResolver = createCssResolver(['letterSpacing'], ({letterSpacing}) => `letter-spacing:${letterSpacing};`)
const lineHeightResolver = createCssResolver(['lineHeight'], ({lineHeight}) => `line-height:${lineHeight};`)
const getColorResolver = themeData => createCssResolver(['color'], ({color}) => `color:${/color_/.test(color) ? themeData.color[color.split('color_')[1]] : color};`)
const getBackgroundColorResolver = themeData => createCssResolver(['backgroundColor'], ({backgroundColor}) => `background-color:${/color_/.test(backgroundColor) ? themeData.color[backgroundColor.split('color_')[1]] : backgroundColor};`)

const simpleFontSizeResolver = createCssResolver(['fontSize'], ({fontSize}) => `font-size:${fontSize};`)

const FALLBACK_MINIMUM_VIEWPORT = 250
const FALLBACK_MAXIMUM_VIEWPORT = 1920

const textScaleResolver = (minViewportSize = FALLBACK_MINIMUM_VIEWPORT, maxViewportSize = FALLBACK_MAXIMUM_VIEWPORT) =>
    createCssResolver(
        ['textScale__minFontSize', 'textScale__maxFontSize'],
        ({textScale__minFontSize, textScale__maxFontSize}) =>
            `font-size:calc(${textScale__minFontSize}px + (${textScale__maxFontSize} - ${textScale__minFontSize}) * ((100vw - ${minViewportSize} * 1px) / (${maxViewportSize} - ${minViewportSize})));`
    )

module.exports = (envMinViewport, envMaxViewport, themeData) => ({
    colorResolver: getColorResolver(themeData),
    backgroundColorResolve: getBackgroundColorResolver(themeData),
    fontFamilyResolver,
    fontWeightResolver,
    fontStyleResolver,
    textDecorationResolver,
    textAlignResolver,
    letterSpacingResolver,
    lineHeightResolver,
    fontSizeResolver: styleDataItem => textScaleResolver(envMinViewport, envMaxViewport)(styleDataItem) || simpleFontSizeResolver(styleDataItem)
})