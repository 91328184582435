'use strict'

const _ = require('lodash')

const GROUP_TYPES = {
    LEGACY_GAPS: 'LEGACY_GAPS',
    OTHER: 'OTHER',
    PAGES_CONTAINER: 'PAGES_CONTAINER',
    PINNED: 'PINNED',
    SOAP: 'SOAP',
    STRUCTURAL: 'STRUCTURAL'
}

const isFixed = compLayout => compLayout && !!compLayout.fixedPosition
const isPinned = compLayout => isFixed(compLayout) && !!compLayout.docked

const createOtherGroup = components => ({type: GROUP_TYPES.OTHER, components})
const createPinnedGroup = (components, groupId) => ({type: GROUP_TYPES.PINNED, components, id: groupId})
const createSOAPGroup = (components, groupId) => ({type: GROUP_TYPES.SOAP, components, id: groupId})
const createStructuralGroup = components => ({type: GROUP_TYPES.STRUCTURAL, components})

const isPagesContainer = compId => compId === 'PAGES_CONTAINER'
const isPinnedBreakpoint = (compId, compLayout) => {
    if (isPagesContainer(compId)) {
        return true
    }

    return (compId === 'SITE_HEADER' || compId === 'SITE_FOOTER') && isFixed(compLayout)
}

const reorderDOMForA11y = (groupedComponents, groupedComponentsByType, childrenLayout) => {
    const siteHeaderInd = _.findIndex(groupedComponents, group => group === groupedComponentsByType.SITE_HEADER)
    const pagesContainerInd = _.findIndex(groupedComponents, group => group === groupedComponentsByType.PAGES_CONTAINER)
    const siteFooterInd = _.findIndex(groupedComponents, group => group === groupedComponentsByType.SITE_FOOTER)

    //map between structural groups and their initial DOM position
    const structuralIndexes = {
        [siteHeaderInd]: 'SITE_HEADER',
        [pagesContainerInd]: 'PAGES_CONTAINER',
        [siteFooterInd]: 'SITE_FOOTER'
    }

    const sortedStructuralIndexes = _.map(_.sortBy(_.keys(structuralIndexes)), Number)

    // pinned groups should get z-index according to their initial DOM order
    const zIndexMap = {
        [`pinnedBefore${structuralIndexes[sortedStructuralIndexes[0]]}`]: 50,
        [`pinnedBefore${structuralIndexes[sortedStructuralIndexes[1]]}`]: 52,
        [`pinnedBefore${structuralIndexes[sortedStructuralIndexes[2]]}`]: 54,
        PINNED_AFTER: 56
    }

    //reorder component's groups according to correct DOM order for A11y
    const reorderedGroupedComponents = []
    // Site Header
    if (groupedComponentsByType.pinnedBeforeSITE_HEADER) {
        groupedComponentsByType.pinnedBeforeSITE_HEADER.zIndex = zIndexMap.pinnedBeforeSITE_HEADER
        reorderedGroupedComponents.push(groupedComponentsByType.pinnedBeforeSITE_HEADER)
    }
    reorderedGroupedComponents.push(groupedComponentsByType.SITE_HEADER)
    // SOSP
    if (groupedComponentsByType.SOSP_CONTAINER_CUSTOM_ID) {
        reorderedGroupedComponents.push(groupedComponentsByType.SOSP_CONTAINER_CUSTOM_ID)
    }
    // Pages Container and SOAP
    if (groupedComponentsByType.pinnedBeforePAGES_CONTAINER) {
        groupedComponentsByType.pinnedBeforePAGES_CONTAINER.zIndex = zIndexMap.pinnedBeforePAGES_CONTAINER
        reorderedGroupedComponents.push(groupedComponentsByType.pinnedBeforePAGES_CONTAINER)
    }
    if (groupedComponentsByType.soapBeforePagesContainer) {
        reorderedGroupedComponents.push(groupedComponentsByType.soapBeforePagesContainer)
    }
    reorderedGroupedComponents.push(groupedComponentsByType.PAGES_CONTAINER)
    if (groupedComponentsByType.soapAfterPagesContainer) {
        reorderedGroupedComponents.push(groupedComponentsByType.soapAfterPagesContainer)
    }
    // Site Footer
    if (groupedComponentsByType.pinnedBeforeSITE_FOOTER) {
        groupedComponentsByType.pinnedBeforeSITE_FOOTER.zIndex = zIndexMap.pinnedBeforeSITE_FOOTER
        reorderedGroupedComponents.push(groupedComponentsByType.pinnedBeforeSITE_FOOTER)
    }
    reorderedGroupedComponents.push(groupedComponentsByType.SITE_FOOTER)
    // Back To Top Button
    if (groupedComponentsByType.BACK_TO_TOP_BUTTON) {
        reorderedGroupedComponents.push(groupedComponentsByType.BACK_TO_TOP_BUTTON)
    }
    // Quick Action Bar
    if (groupedComponentsByType.QUICK_ACTION_BAR) {
        reorderedGroupedComponents.push(groupedComponentsByType.QUICK_ACTION_BAR)
    }
    // Pinned Last
    if (groupedComponentsByType.pinnedAfter) {
        groupedComponentsByType.pinnedAfter.zIndex = zIndexMap.PINNED_AFTER
        reorderedGroupedComponents.push(groupedComponentsByType.pinnedAfter)
    }

    //set z-index on SITE_HEADER if needed
    if (isFixed(childrenLayout.SITE_HEADER)) {
        childrenLayout.SITE_HEADER.zIndex = zIndexMap.pinnedBeforeSITE_HEADER + 1
    } else if (siteHeaderInd > pagesContainerInd || siteHeaderInd > siteFooterInd) {
        childrenLayout.SITE_HEADER.zIndex = 50
    }

    //set z-index on SITE_FOOTER if needed
    if (isFixed(childrenLayout.SITE_FOOTER)) {
        childrenLayout.SITE_FOOTER.zIndex = zIndexMap.pinnedBeforeSITE_FOOTER + 1
    }

    return reorderedGroupedComponents
}

const getMasterPageChildrenGroups = (childrenIds, childrenLayout, isMobileView, shouldReorderDOM) => {
    const structuralIDs = isMobileView ?
        {SITE_HEADER: true, SITE_FOOTER: true, PAGES_CONTAINER: true, BACK_TO_TOP_BUTTON: true, SOSP_CONTAINER_CUSTOM_ID: true, QUICK_ACTION_BAR: true} :
        {SITE_HEADER: true, SITE_FOOTER: true, PAGES_CONTAINER: true, BACK_TO_TOP_BUTTON: true}
    const isStructural = id => !!structuralIDs[id]

    let pinnedAgg = []
    let soapAgg = []
    let pageContainerIndex = 0
    let lastPinnedBreakpointId = ''

    const groupedComponentsByType = {}

    const groupedComponents = childrenIds.reduce((result, childId) => {
        if (isPinned(childrenLayout[childId]) && !isStructural(childId)) {
            pinnedAgg.push(childId)
            return result
        }

        if (isPinnedBreakpoint(childId, childrenLayout[childId])) {
            lastPinnedBreakpointId = childId

            if (!_.isEmpty(pinnedAgg)) {
                const pinnedGroup = createPinnedGroup(pinnedAgg, `pinnedBefore${childId}`)
                groupedComponentsByType[`pinnedBefore${childId}`] = pinnedGroup
                result.push(pinnedGroup)
                pinnedAgg = []
            }
        }

        if (isPagesContainer(childId)) {
            if (!_.isEmpty(soapAgg)) {
                const soapGroup = createSOAPGroup(soapAgg, 'soapBeforePagesContainer')
                groupedComponentsByType.soapBeforePagesContainer = soapGroup
                result.push(soapGroup)
                soapAgg = []
            }

            pageContainerIndex = result.length
        }

        if (isStructural(childId)) {
            const structuralGroup = createStructuralGroup([childId])
            groupedComponentsByType[childId] = structuralGroup
            result.push(structuralGroup)
        } else if (isFixed(childrenLayout[childId])) {
            const otherGroup = createOtherGroup([childId])
            groupedComponentsByType[childId] = otherGroup
            result.push(otherGroup)
        } else {
            soapAgg.push(childId)
        }

        return result
    }, [])

    if (!_.isEmpty(pinnedAgg)) {
        const pinnedAfter = createPinnedGroup(pinnedAgg, `pinnedAfter${lastPinnedBreakpointId}`)
        groupedComponentsByType.pinnedAfter = pinnedAfter
        groupedComponents.push(pinnedAfter)
    }
    if (!_.isEmpty(soapAgg)) {
        const soapGroup = createSOAPGroup(soapAgg, 'soapAfterPagesContainer')
        groupedComponentsByType.soapAfterPagesContainer = soapGroup
        groupedComponents.splice(pageContainerIndex + 1, 0, soapGroup)
    }

    return shouldReorderDOM ? reorderDOMForA11y(groupedComponents, groupedComponentsByType, childrenLayout) : groupedComponents
}

module.exports = {
    GROUP_TYPES,
    getMasterPageChildrenGroups
}
